.InputFromTo
  .DayPicker-Day--selected:not(.DayPicker-Day--start):not(.DayPicker-Day--end):not(.DayPicker-Day--outside) {
  background-color: #f0f8ff !important;
  color: #4a90e2;
}
.InputFromTo .DayPicker-Day {
  border-radius: 0 !important;
}
.InputFromTo .DayPicker-Day--start {
  border-top-left-radius: 50% !important;
  border-bottom-left-radius: 50% !important;
}
.InputFromTo .DayPicker-Day--end {
  border-top-right-radius: 50% !important;
  border-bottom-right-radius: 50% !important;
}
.InputFromTo .DayPickerInput-Overlay {
  width: 550px;
}
.InputFromTo-to .DayPickerInput-Overlay {
  margin-left: -198px;
}
.DateInput__small {
  width: 100px;
}

.DateInput_input__small {
  text-align: center;
  font-size: 13px;
  background-color: rgb(249, 250, 252);
  color: black;
  cursor: pointer;
}

.CalendarDay__default {
  font-size: 11px;
}

.DateRangePicker {
  width: 100%;
}

.DateRangePicker_picker {
  right: -245px !important;
}

.DateRangePickerInput {
  border: 1px solid #e5eaee;
  border-radius: 3px;
  background-color: rgb(249, 250, 252);
  width: 100%;
  height: 36px;
}

.CalendarDay__selected_span {
  background: #eff7ff;
  border: 1px solid #ccdef1;
  color: #00558b;
}

.CalendarDay__selected_span:hover {
  background: #00558b;
  border: 1px solid #ccdef1;
  color: #fff;
}

.CalendarDay__hovered_span {
  background: #eff7ff;
  border: 1px solid #ccdef1;
  color: #00558b;
}

.CalendarDay__hovered_span:hover {
  background: #eff7ff;
  border: 1px solid #ccdef1;
  color: #00558b;
}

.CalendarDay__selected,
.CalendarDay__selected:active,
.CalendarDay__selected:hover {
  background: #00558b;
  border: 1px solid #ccdef1;
  color: #fff;
}

.DateInput_input__focused {
  border-bottom: 2px solid #00558b;
}
