.RouteCompareTooltip {
  width: 100%;
  border-collapse: collapse;
  font-size: 0.75em;
}

.RouteCompareTooltip th {
  text-align: left;
  font-style: italic;
  font-weight: normal;
  text-transform: uppercase;
  color: #B0B4BA;
}

.RouteCompareTooltip td {
  vertical-align: middle;
  color: #B0B4BA;
  padding: 0 4px 0 4px;
}

.RouteCompareTooltip b {
  color: black;
}

.RouteRectangle {
  width: 25px;
  height: 7px;
}
