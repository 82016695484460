* {
  box-sizing: border-box;
}

body {
  min-width: 1200px; /* suppose you want minimun width of 1000px */
  width: auto !important; /* Firefox will set width as auto */
  width: 1200px; /* As IE ignores !important it will set width as 1000px; */
  height: 100%;
}

.full_width {
  width: 100%;
}

.container {
  max-width: 100%;
}

.cm_nav_button {
  height: 100%;
  margin-left: -24px;
}

.page-layout__viewport {
  padding-top: 4rem;
}

.page-layout__nav-item--active {
  font-weight: bold;
  text-decoration: underline;
}

.cm-app {
  margin: 0px;
  width: 100%;
  height: 100%;
  padding-left: 0px;
  padding-right: 0px;
}
