html {
  font-family: 'Roboto', 'Helvetica Neue', 'Helvetica', Arial, sans-serif;
  /* ie scrollbars amirite??? */
  -ms-overflow-style: -ms-autohiding-scrollbar;  
}

html,
body {
  margin: 0;
  padding: 0;
  height: 100%;
}

*,
*:before,
*:after {
  box-sizing: border-box;
}

 /* Remove Edge eye icon on password field, we have our own */
input::-ms-reveal {
  display: none;
}

#root {
  display: flex;
  height: 100%;
}

.text-center {
  text-align: center;
}

 /* Hides MapBox logo from maps */
.mapboxgl-ctrl-logo {
  display: none !important;
}

 /* Remove scroll on the body when react-modal is open */
.ReactModal__Body--open {
  overflow: hidden;
}
